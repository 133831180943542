/*******************************
         Site Overrides
*******************************/

.ui.page.modals {
  padding: 0;
}

.ui.fullscreen.modal {
  width: 100% !important;
  height: 100%;
  margin: 0 !important;
  padding: 0;

  & .content {
    height: 100%;
    padding: 0;
  }
}
.ui.multiple.dropdown {
  padding: 0.22619048em .1em 0.12619048em 0.25714286em;
}
.ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text {
  color: @black;
}
.ui.selection.dropdown{
  color: @black;
}
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
  border-color: @black-light;
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}
.ui.selection.active.dropdown .menu > .item {
  &:extend(.copy-2);
  color: @black;
}
.ui.selection.visible.dropdown > .text:not(.default) {
  color: @black;
  font-weight: 300;
}