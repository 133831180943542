/*******************************
         Site Overrides
*******************************/

.ui.container.details {
  padding: 8%;
  height: 100%;
  overflow: auto;
}

.ui.container.section {
  margin-top: 32px;
}

.ui.container.section .title-2 {
  margin-bottom: 8px;
}
