/*******************************
         Site Overrides
*******************************/

.title-1 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}

.title-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
}

.title-3 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
}

.title-4 {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 32px;
  color: #2C1EBE;
}

.copy-1 {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #0D1D3B;
}

.copy-2 {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
}

.copy-3 {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
}

.copy-4 {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
}

.navig {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.badge {
  display: flex;
  width: 52px;
  height: 52px;
  align-items: center;
  justify-content: center;
  border: 1px solid @black-light;
  border-radius: 50%;
  background-color: @white;
  color: @black;
}

.badge.resolved .icon {
  font-size: 18px !important;
  padding: 0 !important;
}

.badge.resolved.high {
  border-color: @risk-high;
}

.badge.resolved.high .icon {
  color: @risk-high !important;
}

.badge.resolved.medium {
  border-color: @risk-medium;
}

.badge.resolved.medium .icon {
  color: @risk-medium !important;
}

.badge.low {
  border-color: @risk-low;
}

.badge.resolved.low .icon {
  color: @risk-low !important;
}

.badge.permitted {
  border-color: @risk-low;
}

.badge.resolved.permitted .icon {
  color: @risk-low !important;
}

.badge.pending {
  border-color: @black;
}

.badge.resolved.pending .icon {
  color: @black !important;
}

.badge.active {
  background-color: @risk-low;
  color: @privitar-blue;
  border-color: @risk-low;
}

.badge.active.high {
  background-color: @risk-high;
  border-color: @risk-high;
  color: #CF2C67;
}

.badge.active.medium {
  background-color: @risk-medium;
  border-color: @risk-medium;
  color: #D49713;
}

.badge.active.permitted {
  border-color: @risk-low;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: #CF2C67;
}
