/*******************************
         Site Overrides
*******************************/

.ui.massive.text.loader {
  padding-top: 160px;
}

.ui.massive.loader:before, .ui.massive.loader:after {
  width: 160px;
  height: 160px;
  margin-left: -80px;
  border-width: 12px;
}

.ui.inverted.dimmer .ui.loader:before {
  border-color: #E5E5E5;
}

.ui.inverted.dimmer .ui.loader:after {
  border-color: @privitar-blue transparent transparent;
}
