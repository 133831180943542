/*******************************
         Site Overrides
*******************************/

.ui.basic.button.tertiary {
  padding: 0 12px;
  border: 1px solid @black-light;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
  color: #3E4A62 !important;
  box-shadow: none;
  margin: 0;
  align-self: center;
}

.ui.primary.button{
  border-radius: 20px
}

.ui.transparent.button{
  background-color: transparent;
  color: #2C1EBE;
  border: 1px solid #2C1EBE;
}

.ui.basic.button.tertiary:hover {
  color: @white !important;
  background: #3E4A62 !important;
}

.ui.basic.button.tertiary:disabled {
  border-color: @black-lightest;
  color: @black-lightest;
}

.ui.basic.button.secondary {
  padding: 0 16px;
  height: 32px;
  background:@black;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: @red;
}

.ui.basic.button.secondary:hover {
  color: @white;
  background: @kormoon-blue-light;
}

.ui.basic.button.secondary:disabled {
  background: none;
  height: 30px;
  border: 1px solid @black-lightest;
  color: @black-lightest;
}
