/*******************************
        Site Overrides
*******************************/

.ui.message.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  border-radius: 0;
}
