/*******************************
         Site Overrides
*******************************/

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  content: '\e800';
  background: @privitar-blue;
  color: @white !important;
  border-radius: 3px;
}